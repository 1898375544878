import React from 'react';
import PropTypes from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { graphql } from 'gatsby';

import Header from '../components/header';
import Footer from '../components/footer';
import DynamicComponent from '../components/dynamicComponent';
import Headline from '../components/headline';
import Layout from '../components/layout';

const ClientsPage = props => {
  const { client, globalInfo } = props.data;
  const { headline } = client;
  const socialProfiles = props.data.socialProfiles ? props.data.socialProfiles.edges.map(item => item.node) : [];
  const pages = props.data.pages
    ? props.data.pages.edges.filter(item => item.node.slug && item.node.title).map(item => item.node)
    : [];
  const sections = client.sections.filter(section => section.id && !section.id.includes('dummy')); // filter dummy content
  const { locale } = props.pageContext;
  return (
    <Layout>
      <HelmetDatoCms seo={client.seoMetaTags} />
      {headline.title && <Headline title={headline.title.md.html} slug={headline.slug} locale={locale} />}
      <Header
        socialProfiles={socialProfiles}
        image={client.backgroundImage}
        title={client.title.md.html}
        subTitle={client.subTitle.md.html}
        pages={pages}
        fullMenu
      />

      {/* Dynamic Sections */}
      {sections.map(section => (
        <DynamicComponent {...section} key={section.id} />
      ))}

      <Footer
        address={globalInfo.address}
        copyright={globalInfo.copyright}
        hasInternationalization={globalInfo.hasInternationalization}
        socials={socialProfiles}
        pages={pages}
        location={props.location}
      />
    </Layout>
  );
};

ClientsPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export const query = graphql`
  query ClientsPageQuery($locale: String!) {
    client: datoCmsClient(locale: { eq: $locale }) {
      headline {
        title: titleNode {
          md: childMarkdownRemark {
            html
          }
        }
        slug
      }
      title: titleNode {
        md: childMarkdownRemark {
          html
        }
      }
      subTitle: subTitleNode {
        md: childMarkdownRemark {
          html
        }
      }
      backgroundImage {
        sizes(maxWidth: 2000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      sections {
        __typename
        ...SingularContentFragment
        ...TripleColumnContentFragment
        ...MultipleIconContentFragment
        ...ArticleLinksFragment
        ...CallToActionLinkFragment
        ...QuoteLinkFragment
        ...BannerFragment
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    socialProfiles: allDatoCmsSocialProfile(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          ...SocialProfileFragment
        }
      }
    }
    globalInfo: datoCmsGlobalInfo {
      ...GlobalInfoFragment
    }
    pages: allDatoCmsPage(filter: { locale: { eq: $locale }, showInMenu: { eq: true } }) {
      edges {
        node {
          ...PageFragment
        }
      }
    }
  }
`;

export default ClientsPage;
